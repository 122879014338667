import React from "react";
import "./index.css";

const Work = () => {
    return (
        <div id='work_div'>
            <p className='fs-4'><i className='fa-solid fa-briefcase'/> Work</p>
            <hr/>
            <div id='work_items'>
                <ul>
                    <li>
                        <div className='work_item'>
                            <p className='fs-4'>Application Developer<sup><em>[Augmented to Banglalink]</em></sup></p>
                            <p className='fs-5'>FrontDesk Bangladesh Limited</p>
                            <p className='fs-7'>Dhaka, Bangladesh</p>
                            <p>July 2023 - December 2024</p>
                        </div>
                    </li>
                    <br/>
                    <li>
                        <div className='work_item'>
                            <p className='fs-4'>Junior Software Engineer</p>
                            <p className='fs-5'>Tech Cloud Ltd.</p>
                            <p className='fs-7'>Dhaka, Bangladesh</p>
                            <p>January 2023 - July 2023</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Work;
